<template>
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">
        <!-- Brand logo-->
        <!-- /Brand logo-->
        <!-- Left Text-->
        <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
          <div
            class="w-100 d-lg-flex align-items-center justify-content-center px-5"
          >
            <b-img fluid :src="imgUrl" alt="Login V2" />
          </div>
        </b-col>
        <!-- /Left Text-->
  
        <!-- Login-->
        <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <b-card-title title-tag="h2" class="font-weight-bold mb-1">
              Welcome 👋
            </b-card-title>
            <b-card-text class="mb-2">
              Please sign-in to your account and start the adventure
            </b-card-text>
  
            <!-- form -->
  
            <validation-observer ref="loginValidation">
              <b-form class="auth-login-form mt-2" @submit.prevent>
                <!-- submit buttons -->
                <b-button
                  id="login-button"
                  type="submit"
                  variant="primary"
                  block
                  @click="signIn"
                >
                  Sign in
                </b-button>
              </b-form>
            </validation-observer>
          </b-col>
        </b-col>
        <!-- /Login-->
      </b-row>
    </div>
  </template>
  
  <script>
  /* eslint-disable global-require */
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import VuexyLogo from "@core/layouts/components/Logo.vue";
  import {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
  } from "bootstrap-vue";
  import useJwt from "@/auth/jwt/useJwt";
  import { required, email } from "@validations";
  import { usePasswordVisibility } from "@core/mixins/ui/useForms";
  import store from "@/store/index";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import { ref, computed, onMounted } from "@vue/composition-api";
  import { useToast } from "vue-toastification/composition";
  import router from "@/router";
  import { getHomeRouteForLoggedInUser } from "@/auth/utils";
  
  export default {
    components: {
      BRow,
      BCol,
      BLink,
      BFormGroup,
      BFormInput,
      BInputGroupAppend,
      BInputGroup,
      BFormCheckbox,
      BCardText,
      BCardTitle,
      BImg,
      BForm,
      BButton,
      VuexyLogo,
      ValidationProvider,
      ValidationObserver,
    },
    setup() {
      const status = ref("");
      const password = ref("");
      const userEmail = ref("");
      const appId = 98;
      const sideImg = require("@/assets/images/pages/login-v2.svg");
      const toast = useToast();
      const { passwordFieldType, togglePasswordVisibility, passwordToggleIcon } =
        usePasswordVisibility();
      const imgUrl = computed(
        () =>
          "https://storage.googleapis.com/cdn-p.niso.dev/674f66d1-9e39-443f-b6d4-160c0f0349e9.jpg"
      );
      // #template refs
      const loginValidation = ref(null);
      const loginForm = ref(null);
  
      const validationForm = () => {
        loginValidation.value.validate().then((success) => {
          if (success) {
            toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Signing in . . .",
                icon: "CoffeeIcon",
                variant: "info",
              },
            });
            useJwt
              .login({
                username: userEmail.value,
                password: password.value,
                applicationId: appId,
                isFromLoginScreen: true,
              })
              .then((response) => {
                if (!response.data.accessToken) {
                  toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: "Can't login. Please check your credentials",
                      icon: "AlertCircleIcon",
                      variant: "danger",
                    },
                  });
                  return;
                }
                const userData = response.data.userInfo;
  
                useJwt.setToken(response.data.accessToken.code);
                useJwt.setRefreshToken(response.data.refreshToken.code);
                localStorage.setItem("userData", JSON.stringify(userData));
                userData.ability = [{ action: "manage", subject: "all" }];
                store.dispatch("pamis/GetNavOrgTree");
                
                // if (!localStorage.getItem("redirectURL")) {
                router
                  .replace(getHomeRouteForLoggedInUser(userData.roles[0]))
                  .then(() => {
                    toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: "Welcome",
                        icon: "CoffeeIcon",
                        variant: "success",
                      },
                    });
                  });
                // }
                // else {
                //   router.replace(localStorage.getItem("redirectURL")).then(() => {
                //     toast({
                //       component: ToastificationContent,
                //       position: "top-right",
                //       props: {
                //         title: `Welcome`,
                //         icon: "CoffeeIcon",
                //         variant: "success",
                //       },
                //     });
                //   });
                // }
                // localStorage.removeItem("redirectURL");
                useJwt.setClientLogo(`${window.location.origin}/logo.png`);
              })
              .catch((error) => {
                toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: "Can not login. Please check your credentials",
                    variant: "danger",
                    icon: "AlertCircleIcon",
                    // text: ` ${error.response.data.status} - ${error.response.data.message}. `,
                    text: ` ${error.response} - ${error}. `,
                  },
                });
                // loginForm.value.setErrors(error.response.data.error);
              });
          }
        });
      };
      const signIn = () => {
        store.dispatch("auth/signin");
      };
  
      onMounted(() => {
        // store.dispatch('auth/signin')
      });
      return {
        status,
        password,
        userEmail,
        appId,
        sideImg,
        required,
        email,
        passwordFieldType,
        togglePasswordVisibility,
        passwordToggleIcon,
        imgUrl,
        loginValidation,
        loginForm,
        validationForm,
        signIn,
      };
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/pages/page-auth.scss";
  </style>
  